const getLanguage = (lang) => {
  switch (typeof lang) {
    case "object":
      return lang;
    case "string":
      return require(`libs/suneditor/src/lang/${lang}.js`);
    default:
      return undefined;
  }
};

export default getLanguage;
